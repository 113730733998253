import { useApplicationState } from "../data/application-state";
import { Button } from "../components/button";
import _ from "lodash";

export const Settings = ({ navigate }) => {
  const { password, setPassword } = useApplicationState();
  return (
    <div>
      <h2>Settings</h2>
      <label>
        Lösenord:
        <input
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </label>
      {!_.isEmpty(password) && (
        <Button
          onClick={() => {
            navigate("home");
            window.location.reload();
          }}
        >
          Home
        </Button>
      )}
    </div>
  );
};
