import { Stack } from "./stack.jsx";
import { ImageThumbnail } from "./image-thumbnail.jsx";
import { useState } from "react";
import { Tag } from "./tag.jsx";
import { useApplicationState } from "../data/application-state.js";
import { Grid } from "./grid.jsx";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)`
  max-width: 300px;
`;

export const Item = ({ item, ...props }) => {
  const [selectedPhoto, setSelectedPhoto] = useState(item.photos[0]);
  const { saveState } = useApplicationState();

  return (
    <Wrapper direction={"column"} gap={"8px"} {...props}>
      <ImageThumbnail size={"full"} id={selectedPhoto} />
      {item.photos.length > 1 && (
        <Grid columns={"1fr 1fr 1fr 1fr"}>
          {item.photos.map((photoId) => (
            <ImageThumbnail
              key={`thumbnail${photoId}`}
              size={"sm"}
              id={photoId}
              onClick={() => setSelectedPhoto(photoId)}
              onMouseOver={() => setSelectedPhoto(photoId)}
            />
          ))}
        </Grid>
      )}
      <Stack direction={"row"} gap={"4px"}>
        {item.tags.map((tag) => (
          <Tag key={`tag${tag}`}>{tag}</Tag>
        ))}
      </Stack>
      {item.isDiscarded ? (
        <button
          onClick={() => {
            if (confirm("Återställ?")) {
              saveState({ items: { [item.id]: { isDiscarded: false } } });
            }
          }}
        >
          Återställ
        </button>
      ) : (
        <button
          onClick={() => {
            if (confirm("Ta bort?")) {
              saveState({ items: { [item.id]: { isDiscarded: true } } });
            }
          }}
        >
          Ta bort
        </button>
      )}
    </Wrapper>
  );
};
