import { theme } from "../theme.js";
import styled from "@emotion/styled";

export const Input = styled.input({
  padding: theme.space.md,
  borderRadius: theme.radii.md,
  backgroundColor: theme.colors.white,
  color: theme.colors.primary,
  fontSize: theme.font.md,
  border: theme.border.md,
  width: "100%",
  boxSizing: "border-box",
});
