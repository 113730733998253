import styled from "@emotion/styled";
import { theme } from "../theme.js";

export const Tag = styled.button`
  font-size: ${theme.font.md}px;
  font-family: ${theme.fontFamily.body};
  padding: ${theme.space.sm}px;
  border-radius: ${theme.radii.sm}px;
  border: ${theme.border.sm};
  color: ${(props) =>
    props.isActive ? theme.colors.white : theme.colors.black};
  background-color: ${(props) =>
    props.isActive
      ? theme.colors.background.primary
      : theme.colors.background.beige};
`;
