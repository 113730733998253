import { CameraCapture } from "../components/camera.jsx";
import { Headline } from "../components/headline.jsx";
import { Container } from "../components/container.jsx";
import { Input } from "../components/input.jsx";
import { Stack } from "../components/stack.jsx";
import { Tag } from "../components/tag.jsx";
import { Button } from "../components/button.jsx";
import { Fragment, useState } from "react";
import { theme } from "../theme.js";
import styled from "@emotion/styled";
import knownTags from "../data/tags.json";
import { useApplicationState } from "../data/application-state.js";
import { v4 as uuidv4 } from "uuid";

const Header = styled.div({
  width: 'calc("100% + 60px")',
  background: theme.colors.background.primary,
  margin: -30,
  marginBottom: 30,
  borderRadius: `${theme.radii.md}px ${theme.radii.md}px 0 0`,
  padding: 12,
});

const fuzzyFind = (pattern, string) => {
  string = string.toLowerCase();
  pattern = pattern.toLowerCase().replace(/\s/g, "");
  return pattern.split("").reduce((result, character) => {
    const index = string.indexOf(character);
    if (index === -1) {
      return false; // Character not found
    }
    string = string.slice(index + 1); // Slice the string from the next character
    return result;
  }, true);
  // return pattern
  //   .split("")
  //   .reduce(
  //     (result, character) =>
  //       !console.log('"' + string + '"', character) &&
  //       result &&
  //       string.includes(character) &&
  //       (string = string.slice(string.indexOf(character) + 1)),
  //     true,
  //   );
};

export default function NewItem({ navigate }) {
  const [tags, setTags] = useState([]);
  const [filter, setFilter] = useState("");
  const hasFilter = filter.length > 0;
  const [photos, setPhotos] = useState([]);
  const [container, setContainer] = useState(null);
  const { getContainers, saveState } = useApplicationState();

  const saveItem = () => {
    const id = uuidv4();
    const item = {
      id,
      tags,
      photos,
      container,
    };
    console.log("item", item);
    saveState({ items: { [id]: item } });
    navigate("home");
  };

  const containers = getContainers();

  return (
    <Stack direction={"column"} gap={"8px"}>
      <Container color={"beige"}>
        <Header>
          <button onClick={() => navigate("home")}>Tillbaka</button>
          <Headline>Ny sak</Headline>
        </Header>
        <Stack direction={"column"} gap={"8px"}>
          <CameraCapture photos={photos} setPhotos={setPhotos} />
          {Object.keys(knownTags).map((category, i) => {
            const condition = eval(knownTags[category].condition);
            if (!condition(tags)) return null;

            let tagsToShow = knownTags[category].tags;
            if (hasFilter) {
              tagsToShow = tagsToShow.filter((t) => fuzzyFind(filter, t));
            }

            return (
              <Fragment key={"fragment" + i}>
                <Headline
                  style={{ color: theme.colors.black }}
                  key={"headline" + i}
                >
                  {category}
                </Headline>
                <Stack key={"stack" + i} direction={"row"} gap={"8px"}>
                  {tagsToShow.map((tag, i) => {
                    const isActive = tags.some((t) => t === tag);
                    return (
                      <Tag
                        key={"tag" + i}
                        onClick={() => {
                          isActive
                            ? setTags(tags.filter((t) => t !== tag))
                            : setTags([...tags, tag]);
                        }}
                        isActive={isActive}
                      >
                        {tag}
                      </Tag>
                    );
                  })}
                </Stack>
              </Fragment>
            );
          })}
          <Input
            placeholder={"Ny tagg/Sök"}
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
          />
          {hasFilter && (
            <Button
              onClick={() => {
                setTags([...tags, filter]);
                knownTags.Kategori.tags.push(filter);
                setFilter("");
              }}
            >
              Lägg till tagg
            </Button>
          )}
        </Stack>
        <Headline style={{ color: theme.colors.black }}>Behållare</Headline>
        <Stack direction={"row"} gap={"8px"}>
          {Object.keys(containers).map((containerKey, i) => {
            const isActive = container === containerKey;
            return (
              <Tag
                key={"container" + i}
                onClick={() => {
                  setContainer(containerKey);
                }}
                isActive={isActive}
              >
                {containers[containerKey].name}
              </Tag>
            );
          })}
        </Stack>
      </Container>
      <Button onClick={saveItem}>Spara</Button>
    </Stack>
  );
}
