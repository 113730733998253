import styled from "@emotion/styled";
import { theme } from "../theme.js";

const StyledButton = styled.button({
  padding: theme.space.md,
  borderRadius: theme.radii.md,
  backgroundColor: theme.colors.background.primary,
  boxShadow: theme.shadows.innerSmall,
  border: theme.border.md,
  color: theme.colors.white,
  fontFamily: theme.fontFamily.body,
  fontSize: theme.font.md,
  ":active": {
    boxShadow: "none",
  },
});

export const Button = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
);
