import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/react";
import { theme } from "../theme.js";
import { Center } from "./center.jsx";
import { Stack } from "./stack.jsx";
import { ImageThumbnail } from "./image-thumbnail.jsx";
import { v4 as uuidv4 } from "uuid";
import { savePhoto, uploadPhoto } from "./photo.jsx";
import {useApplicationState} from "../data/application-state";

const VideoContainer = styled.div({
  width: "100%",
  maxWidth: 300,
  height: "auto",
  aspectRatio: "1 / 1",
  position: "relative",
  boxShadow: theme.shadows.outer,
  borderRadius: theme.radii.md + 2,
  border: theme.border.md,
  boxSizing: "border-box",
  display: "flex",
});

const Video = styled.video({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: theme.radii.md,
});

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const PhotoAnimation = styled.div`
  display: block;
  background: #fff;
  border-radius: ${theme.radii.md}px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  animation: ${(props) =>
    props.shouldAnimate
      ? css`
          ${fadeOut} 0.5s ease-in-out forwards
        `
      : "none"};
`;

const Photo = styled.button({
  background: "#fff",
  width: 60,
  height: 60,
  padding: 10,
  borderRadius: "100%",
  position: "absolute",
  bottom: 20,
  left: "calc(50% - 30px)",
  zIndex: 2,
  border: theme.border.md,
});

const CameraCapture = ({ photos, setPhotos }) => {
  const [isCameraOn, setIsCameraOn] = useState(true);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [photoNumber, setPhotoNumber] = useState(0); // This is only to trigger the animation
  const [draggedPhoto, setDraggedPhoto] = useState(null);
  const { saveState } = useApplicationState();

  const handleDragStart = (index) => () => {
    setDraggedPhoto(index);
  };

  const handleDragOver = (index) => (event) => {
    event.preventDefault(); // Necessary for onDrop to work
    if (index !== draggedPhoto) {
      // Reorder photos
      const newPhotos = [...photos];
      const draggedItem = newPhotos.splice(draggedPhoto, 1)[0];
      newPhotos.splice(index, 0, draggedItem);
      setPhotos(newPhotos);
      setDraggedPhoto(index); // Update the index of the dragged photo
    }
  };

  const handleDragEnd = () => {
    setDraggedPhoto(null);
  };

  const startCamera = async () => {
    if (!isCameraOn) setIsCameraOn(true);
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "environment",
          width: { ideal: 512 },
          height: { ideal: 512 },
        },
      });
      videoRef.current.srcObject = mediaStream;
      setStream(mediaStream);
    } catch (error) {
      // This is expected to happen
      // console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    // TODO: This doesn't run properly
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      setStream(null);
    }
  };

  const captureImage = async () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const width = video.videoWidth;
    const height = video.videoHeight;
    const size = Math.min(width, height); // Size of the square

    let sx, sy, sWidth, sHeight;
    if (width > height) {
      // Video is wider than tall, so crop horizontally
      sx = (width - size) / 2;
      sy = 0;
      sWidth = sHeight = size;
    } else {
      // Video is taller than wide, so crop vertically
      sx = 0;
      sy = (height - size) / 2;
      sWidth = sHeight = size;
    }

    // Set the canvas size to the desired output size
    // canvas.width = size;
    // canvas.height = size;

    context.drawImage(
      video,
      sx,
      sy,
      sWidth,
      sHeight,
      0,
      0,
      canvas.width,
      canvas.height,
    );
    const photoData = canvas.toDataURL("image/jpeg");
    const photoId = uuidv4();
    setPhotoNumber(photoNumber + 1);
    setPhotos([...photos, photoId]);

    await savePhoto(photoId, photoData);
    await uploadPhoto(photoId, photoData, saveState);
  };

  useEffect(() => {
    startCamera();
    return stopCamera();
  }, []);

  return (
    <div>
      <Stack direction={"column"} gap={"8px"}>
        <Center>
          <VideoContainer>
            <Video ref={videoRef} autoPlay playsInline muted />
            <PhotoAnimation shouldAnimate={photoNumber > 0} key={photoNumber} />
            <Photo
              onClick={() => (stream === null ? startCamera() : captureImage())}
            />
          </VideoContainer>
        </Center>
        <Stack direction={"row"} gap={"8px"}>
          {photos.map((photo, i) => (
            <ImageThumbnail
              draggable
              onDragStart={handleDragStart(i)}
              onDragOver={handleDragOver(i)}
              onDragEnd={handleDragEnd}
              onDrop={(event) => event.preventDefault()}
              key={i}
              id={photo}
              onClick={() => {
                setPhotos(photos.filter((element, index) => index !== i));
              }}
            />
          ))}
        </Stack>
      </Stack>
      <canvas
        ref={canvasRef}
        style={{ display: "none" }}
        width={"512"}
        height={"512"}
      />
    </div>
  );
};

export { CameraCapture };
