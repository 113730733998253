import "./App.css";
import { useState } from "react";
import Home from "./pages/home";
import NewItem from "./pages/new-item";
import OwnedItems from "./pages/owned-items";
import DiscardedItems from "./pages/discarded-items";
import { Settings } from "./pages/settings";
import { useApplicationState } from "./data/application-state";

function App() {
  const { password } = useApplicationState();
  const [currentPage, navigate] = useState(password ? "home" : "settings");
  switch (currentPage) {
    case "home":
      return <Home {...{ navigate }} />;
    case "new-item":
      return <NewItem {...{ navigate }} />;
    case "owned-items":
      return <OwnedItems {...{ navigate }} />;
    case "discarded-items":
      return <DiscardedItems {...{ navigate }} />;
    case "settings":
      return <Settings {...{ navigate }} />;
    default:
      return <>Error: Unknown page</>;
  }
}

export default App;
