import _ from "lodash";
import { createContext, useContext, useEffect } from "react";
import axios from "axios";
import useLocalStorage from "./use-localstorage";
import {
  downloadPhoto,
  getIsPhotoUploaded,
  getPhoto,
  uploadPhoto,
} from "../components/photo";

const ApplicationStateContext = createContext();

export const useApplicationState = () => {
  const context = useContext(ApplicationStateContext);
  if (!context) {
    throw new Error(
      "useApplicationState must be used within an ApplicationStateProvider",
    );
  }
  return context;
};

const stillOwned = (items, keys) =>
  keys.filter((key) => !items[key].isDiscarded);
const discarded = (items, keys) => keys.filter((key) => items[key].isDiscarded);
export const inContainer = (containerId) => (items, keys) =>
  keys.filter((key) => items[key].containerId === containerId);
export const withoutContainer = (items, keys) =>
  keys.filter((key) => items[key].containerId == null);

export const inSpace = (spaceId) => (containers, keys) =>
  keys.filter((key) => containers[key].spaceId === spaceId);

export const ApplicationStateProvider = ({ children }) => {
  const [applicationState, setApplicationState] = useLocalStorage(
    {
      items: {},
      containers: {},
      spaces: {},
      uploadedPhotos: {},
    },
    "applicationState",
  );
  const [stateChangeToBeUploaded, setStateChangeToBeUploaded] = useLocalStorage(
    {},
    "stateChangeToBeUploaded",
  );
  const [bufferedNewStateChanges, setBufferedNewStateChanges] = useLocalStorage(
    {},
    "bufferedNewStateChanges",
  );
  const [, setIsUploading] = useLocalStorage(false, "isUploading");
  const [lastDownloadedState, setLastDownloadedState] = useLocalStorage(
    -1,
    "lastDownloadedState",
  );
  const [password, setPassword] = useLocalStorage("", "password");
  // TODO: Store all(?) these in localStorage as well
  // TODO: Ladda hem alla bilder som inte finns i IndexedDB i bakgrunden då och då

  const mergeDownloadedStates = (states, stateId) => {
    if (stateId == null) {
      return;
    }

    let nextState = _.merge({}, applicationState);
    states.forEach((state) => {
      nextState = _.merge({}, nextState, state);
    });
    nextState = _.merge(
      {},
      nextState,
      stateChangeToBeUploaded,
      bufferedNewStateChanges,
    );
    setApplicationState(nextState);
    if (stateId !== null) {
      setLastDownloadedState(stateId);
    }
  };

  const downloadStateChanges = async () => {
    const formData = new FormData();
    formData.append("lastState", lastDownloadedState);
    formData.append("password", password);
    formData.append("function", "download");

    try {
      const response = await axios.post(
        "https://hemlogistik.antonlejon.com/state.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      const { states, stateId } = response.data;
      mergeDownloadedStates(states, stateId);
    } catch (error) {
      console.error("Error downloading state:", error);
    }
  };

  const uploadStateChanges = async () => {
    if (
      _.isEmpty(stateChangeToBeUploaded) &&
      _.isEmpty(bufferedNewStateChanges)
    ) {
      return;
    }

    setIsUploading(true);
    const newState = _.merge(
      {},
      stateChangeToBeUploaded,
      bufferedNewStateChanges,
    );
    setStateChangeToBeUploaded(newState);
    setBufferedNewStateChanges({});

    const formData = new FormData();
    formData.append("stateDiff", JSON.stringify(newState));
    formData.append("lastState", lastDownloadedState);
    formData.append("password", password);
    formData.append("function", "upload");

    try {
      const response = await axios.post(
        "https://hemlogistik.antonlejon.com/state.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      const { states, stateId } = response.data;
      mergeDownloadedStates(states, stateId);
      setStateChangeToBeUploaded({});
    } catch (error) {
      console.error("Error uploading state:", error);
    }

    setIsUploading(false);
  };

  const saveState = (stateDiff) => {
    setApplicationState(_.merge({}, applicationState, stateDiff));
    setBufferedNewStateChanges(_.merge({}, bufferedNewStateChanges, stateDiff));
  };

  useEffect(() => {
    if (!_.isEmpty(bufferedNewStateChanges)) {
      uploadStateChanges();
    }
  }, [stateChangeToBeUploaded, bufferedNewStateChanges]);

  useEffect(() => {
    if (_.isEmpty(password)) {
      return;
    }
    const interval = setInterval(async () => {
      uploadStateChanges();
      downloadStateChanges();
    }, 3000);
    return () => clearInterval(interval);
  }, [
    applicationState,
    lastDownloadedState,
    stateChangeToBeUploaded,
    bufferedNewStateChanges,
    password,
  ]);

  const findMissingPhotos = async (keys) => {
    const photoPromises = keys.map(async (key) => {
      const photo = await getPhoto(key);
      return (photo == null || photo === 'undefined') ? null : key;
    });

    const photos = await Promise.all(photoPromises);
    return keys.filter((key, index) => photos[index] == null);
  };

  const findUnuploadedPhotos = async (keys) => {
    const photoPromises = keys.map(async (key) => {
      const photoUploaded = getIsPhotoUploaded(key, applicationState.uploadedPhotos);
      const isPhotoUploaded = photoUploaded != null && photoUploaded === true
      const photo = await getPhoto(key);
      const photoExists = photo != null && photo !== 'undefined';
      return (photoExists && !isPhotoUploaded) ? key : null;
    });

    const photos = await Promise.all(photoPromises);
    return keys.filter((key, index) => photos[index] != null);
  };

  useEffect(() => {
    const downloadMissingPhotos = async () => {
      if (_.isEmpty(password)) {
        return;
      }
      const items = applicationState.items;
      const itemIds = Object.keys(items);
      const photos = [];
      itemIds.forEach((itemId) => {
        const item = items[itemId];
        const itemPhotos = item.photos;
        if (itemPhotos != null) {
          itemPhotos.forEach((photo) => {
            photos.push(photo);
          });
        }
      });
      const missingPhotos = await findMissingPhotos(photos);

      missingPhotos.forEach((photo) => {
        downloadPhoto(photo);
      });
    };

    const uploadMissingPhotos = async () => {
      const items = applicationState.items;
      const itemIds = Object.keys(items);
      const photos = [];
      itemIds.forEach((itemId) => {
        const item = items[itemId];
        const itemPhotos = item.photos;
        if (itemPhotos != null) {
          itemPhotos.forEach((photo) => {
            photos.push(photo);
          });
        }
      });
      const missingPhotos = await findUnuploadedPhotos(photos);

      missingPhotos.forEach(async (photo) => {
        uploadPhoto(photo, await getPhoto(photo), saveState);
      });
    };

    const downloadInterval = setInterval(() => downloadMissingPhotos(), 1000 * 10);
    const uploadInterval = setInterval(() => uploadMissingPhotos(), 1000 * 10);
    return () => {
      clearInterval(downloadInterval)
      clearInterval(uploadInterval);
    };
  }, [applicationState]);

  function applyFilters(obj, ...functions) {
    let keys = Object.keys(obj);

    functions.forEach((func) => {
      if (typeof func === "function") {
        keys = func(obj, keys);
      }
    });

    return keys.reduce((filteredObj, key) => {
      if (obj.hasOwnProperty(key)) {
        filteredObj[key] = obj[key];
      }
      return filteredObj;
    }, {});
  }

  return (
    <ApplicationStateContext.Provider
      value={{
        getItems: (...filters) => {
          const items = applyFilters(
            applicationState.items,
            stillOwned,
            ...filters,
          );
          return items;
        },
        getDiscardedItems: (...filters) =>
          applyFilters(applicationState.items, discarded, ...filters),
        getContainers: (...filters) =>
          applyFilters(applicationState.containers, ...filters),
        getSpaces: (...filters) =>
          applyFilters(applicationState.spaces, ...filters),
        uploadedPhotos: applicationState.uploadedPhotos,
        saveState,
        password,
        setPassword,
      }}
    >
      {children}
    </ApplicationStateContext.Provider>
  );
};
