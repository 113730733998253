import styled from "@emotion/styled";
import { theme } from "../theme.js";
import { Photo } from "./photo.jsx";

// const Container = styled.div({
//   borderRadius: "8px",
//   width: 60,
//   height: 60,
//   background: theme.colors.background.beige,
//   border: theme.border.sm,
//   fit: "cover",
// });

const StyledPhoto = styled(Photo)`
  border-radius: 8px;
  width: ${(props) => (props.size === "sm" ? "60px" : "100%")};
  height: ${(props) => (props.size === "sm" ? "60px" : "auto")};
  aspect-ratio: 1 / 1;
  background: ${theme.colors.background.beige};
  border: ${theme.border.sm};
  fit: "cover";
  color: ${theme.colors.black};
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageThumbnail = ({ id, size = "sm", ...props }) => {
  return <StyledPhoto id={id} size={size} {...props} />;
};
