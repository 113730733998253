import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import styled from "@emotion/styled";
import { ApplicationStateProvider } from "./data/application-state";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const ScrollContainer = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overscroll-behavior: none;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ApplicationStateProvider>
            <ScrollContainer>
                <App />
            </ScrollContainer>
        </ApplicationStateProvider>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
