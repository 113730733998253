const colors = {
  primary: "#ff7826",
  secondary: "#017867",
  white: "#fefdff",
  black: "#030900",
  background: {
    primary: "#ff7826",
    secondary: "#017867",
    white: "#effffc",
    beige: "#ffeed9",
    trueWhite: "#ffffff",
  },
  logo: {
    primary: "#ff9800",
    secondary: "#51a738",
    tertiary: "#24c2de",
  },
};
export const theme = {
  colors,
  radii: {
    sm: 10,
    md: 30,
  },
  shadows: {
    outer: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
    inner: "inset 0 -90px 90px -90px rgba(0, 0, 0, 0.6)",
    innerSmall: "inset 0 -45px 45px -45px rgba(0, 0, 0, 0.6)",
  },
  space: {
    sm: 10,
    md: 20,
  },
  border: {
    sm: `1.5px solid ${colors.black}`,
    md: `2px solid ${colors.black}`,
  },
  font: {
    sm: "14px",
    md: "18px",
  },
  fontFamily: {
    title: "Geist mono, monospace",
    body: "Geist, sans-serif",
  },
};
