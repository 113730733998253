import { Button } from "../components/button.jsx";
import { Item } from "../components/item.jsx";
import { useApplicationState } from "../data/application-state.js";
import { Stack } from "../components/stack.jsx";
import logo from "../images/logo.svg";
import { Headline } from "../components/headline.jsx";

export default function OwnedItems({ navigate }) {
  const { getItems } = useApplicationState();
  const items = getItems();

  return (
    <Stack direction={"column"} gap={"8px"} style={{ alignItems: "center" }}>
      <img src={logo} alt="logo" width={96} height={96} />
      <Headline>
        Bergslejonens
        <br />
        hemlogistik
      </Headline>
      <Button onClick={() => navigate("home")}>Home</Button>
      <Stack
        direction={"row"}
        gap={"16px"}
        style={{ justifyContent: "center" }}
      >
        {Object.keys(items).map((itemId) => {
          const item = items[itemId];
          return <Item item={item} key={`item${itemId}`} />;
        })}
      </Stack>
    </Stack>
  );
}
