import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../theme.js";

const ContainerPrimitive = styled.div`
  background-color: ${(props) => props.background};
  color: {(props) => props.color};
  box-shadow: ${theme.shadows.inner};
  padding: ${theme.space.md}px;
  border: ${theme.border.md};
  border-radius: ${theme.radii.md}px;
  padding: 30px;
  box-sizing: border-box;
`;

const Container = ({ children, color, ...props }) => {
  switch (color) {
    case "orange":
    default:
      props.background = theme.colors.background.primary;
      props.color = theme.colors.white;
      break;
    case "white":
      props.background = theme.colors.background.white;
      props.color = theme.colors.black;
      break;
    case "beige":
      props.background = theme.colors.background.beige;
      props.color = theme.colors.black;
      break;
  }
  return <ContainerPrimitive {...props}>{children}</ContainerPrimitive>;
};
Container.propTypes = {
  background: PropTypes.string,
  color: PropTypes.oneOf(["orange", "white", "beige"]),
  shadow: PropTypes.string,
};

export { Container };
