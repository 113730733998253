import { useEffect, useState } from "react";
import axios from "axios";
import {useApplicationState} from "../data/application-state";

function openPhotosDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("photos", 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("photos")) {
        db.createObjectStore("photos");
      }
    };

    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = (event) => resolve(event.target.result);
  });
}

export async function savePhoto(id, blob) {
  const db = await openPhotosDB();
  const transaction = db.transaction(["photos"], "readwrite");
  const store = transaction.objectStore("photos");
  store.put(blob, id);
}

export function saveIsPhotoUploaded(id, saveState) {
  saveState({ uploadedPhotos: { [id]: true } });
}

export async function getPhoto(id) {
  const db = await openPhotosDB();
  const transaction = db.transaction(["photos"], "readonly");
  const store = transaction.objectStore("photos");

  return new Promise((resolve, reject) => {
    const request = store.get(id);
    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = (event) => resolve(event.target.result);
  });
}

export function getIsPhotoUploaded(id, uploadedPhotos) {
  return uploadedPhotos[id] || false;
}

export async function uploadPhoto(id, data, saveState) {
  // Send the image to the endpoint
  const formData = new FormData();
  const password = JSON.parse(localStorage.getItem("password"));
  formData.append("id", id);
  formData.append("photo", data);
  formData.append("password", password);
  formData.append("function", "upload");
  try {
    await axios.post("https://hemlogistik.antonlejon.com/photo.php", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    saveIsPhotoUploaded(id, saveState);
  } catch (error) {
    console.error("Error in uploading:", error);
  }
}

export async function downloadPhoto(id) {
  const formData = new FormData();
  const password = JSON.parse(localStorage.getItem("password"));
  formData.append("id", id);
  formData.append("password", password);
  formData.append("function", "download");
  try {
    const response = await axios.post(
      "https://hemlogistik.antonlejon.com/photo.php",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (response.data !== "Photo not found") {
      await savePhoto(id, response.data);
    }
    return response.data;
  } catch (error) {
    console.error("Error in downloading:", error);
  }
}

export const Photo = ({ id, ...rest }) => {
  const [photo, setPhoto] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);
  const { uploadedPhotos, saveState } = useApplicationState();

  async function loadPhoto() {
    setIsLoading(true);
    getPhoto(id).then(async (photo) => {
      if (photo != null) {
        // Fetched photo object from IndexedDB
        setPhoto(photo);
        setIsLoading(false);
        setIsRetrying(false);
        setIsNotFound(false);
        const isUploaded = getIsPhotoUploaded(id, uploadedPhotos);
        if (!isUploaded) {
          await uploadPhoto(id, photo, saveState);
        }
      } else {
        // Download photo
        const downloadedData = await downloadPhoto(id);
        if (downloadedData == null) {
          setIsLoading(false);
          setIsNotFound(false);
          setIsRetrying(true);
        } else if (downloadedData === "Photo not found") {
          setIsLoading(false);
          setIsNotFound(true);
          setIsRetrying(true);
        } else {
          setPhoto(downloadedData);
          setIsLoading(false);
          setIsNotFound(false);
          setIsRetrying(false);
          saveIsPhotoUploaded(id, saveState);
        }
      }
    });
  }

  useEffect(() => {
    loadPhoto();
  }, [id]);

  if (!isLoading && isRetrying) {
    setTimeout(() => loadPhoto(), 1000);
  }

  if (isRetrying) return <div {...rest}>Awaiting network...</div>;
  if (isLoading) return <div {...rest}>Loading</div>;
  if (isNotFound) return <div {...rest}>Photo not found</div>;
  return <img src={photo} alt={"item"} {...rest} />;
};
