import { Button } from "../components/button.jsx";
import { Stack } from "../components/stack.jsx";
import logo from "../images/logo.svg";
import { Headline } from "../components/headline.jsx";

export default function Home({ navigate }) {
  return (
    <Stack direction={"column"} gap={"8px"} style={{ alignItems: "center" }}>
      <img alt={"logo"} src={logo} width={96} height={96} />
      <Headline>
        Bergslejonens
        <br />
        hemlogistik
      </Headline>
      <div />
      <Button onClick={() => navigate("new-item")}>New item</Button>
      <Button onClick={() => navigate("owned-items")}>Owned items</Button>
      <Button onClick={() => navigate("discarded-items")}>
        Discarded items
      </Button>
      <Button onClick={() => navigate("settings")}>Settings</Button>
    </Stack>
  );
}
