import styled from "@emotion/styled";

export const Grid = styled.div`
  display: grid;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: ${(props) =>
    props.columns ? props.columns : "1fr 1fr"};
  grid-template-rows: auto;
  gap: 0.75em;
`;
